/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'backpack3-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5 10v3h6v-3h-1v.5a.5.5 0 01-1 0V10z"/><path pid="1" d="M6 2v.341a6 6 0 00-1.308.653l-.416-1.247a1 1 0 00-1.749-.284l-.77 1.027a1 1 0 00-.149.917l.803 2.407A6 6 0 002 8v5.5A2.5 2.5 0 004.5 16h7a2.5 2.5 0 002.5-2.5V8c0-.771-.146-1.509-.41-2.186l.801-2.407a1 1 0 00-.148-.917l-.77-1.027a1 1 0 00-1.75.284l-.415 1.247A6 6 0 0010 2.34V2a2 2 0 10-4 0m1 0a1 1 0 012 0v.083a6 6 0 00-2 0zm5.941 2.595a6 6 0 00-.8-.937l.531-1.595.77 1.027zM3.86 3.658a6 6 0 00-.8.937L2.557 3.09l.77-1.027zm.18 3.772a4 4 0 017.92 0 .5.5 0 11-.99.142 3 3 0 00-5.94 0 .5.5 0 11-.99-.142M4 9.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5z"/>',
    },
});
